import { Component, Input } from '@angular/core';
import { TranslationPipe } from '../../pipes/translation.pipe';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'cmp-white-box',
    templateUrl: './white-box.component.html',
    styleUrls: ['./white-box.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        TranslationPipe,
    ],
})
export class WhiteBoxComponent {
  @Input() title?: string;
  @Input() complexTitle?: any;
  @Input() fullBorder?: boolean;
}
