<div class="box-space">
  <h3 *ngIf="title">{{ title }}</h3>
  <h3 *ngIf="complexTitle">{{ complexTitle?.title }} <span class="status-title">{{ complexTitle?.statusLabel }}
      <span class="state" [ngClass]="{
        'state-confirm': complexTitle?.statusKey === 'SG_BOOK_STATE_CONFIRM',
        'state-payment-pending': complexTitle?.statusKey === 'SG_BOOK_ACTION_PENDING_PAYMENT',
        'state-process': complexTitle?.statusKey === 'SG_BOOK_STATE_PENDING',
        'state-change': complexTitle?.statusKey === 'SG_BOOK_STATE_CHANGE',
        'state-update': complexTitle?.statusKey === 'SG_BOOK_STATE_MODIFIED',
        'state-cancel': complexTitle?.statusKey === 'SG_BOOK_STATE_CANCELED'
      }">{{ complexTitle?.statusValue | translation }}
      </span>
    </span></h3>
  <div class="white-box card" [ngClass]="{ 'single-border': !fullBorder, 'full-border': fullBorder }">
    <ng-content></ng-content>
  </div>
</div>